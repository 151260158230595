import s from './TwoFeaturedColumnsDisplayStyle.module.scss';
import { FeaturedColumnTextModuleType } from '../types';
import { ModuleHeader } from '../../../components';
import BlockAnchor from '../../../../BlockAnchor';
import OfferCard from './OfferCard';

export interface TwoFeaturedColumnsDisplayStyleProps {
  module: FeaturedColumnTextModuleType;
}

export default function TwoFeaturedColumnsDisplayStyle(
  props: TwoFeaturedColumnsDisplayStyleProps,
) {
  const {
    module: { title, titleSize, subtitle, featuredSubsections, subsections },
  } = props;

  return (
    <div className={s.wrapper}>
      <div className="container">
        {(title || subtitle) && (
          <div className="row">
            <div className="col-xs-12 col-md-7 col-lg-5">
              <div className={s.headerWrapper}>
                <ModuleHeader
                  title={title}
                  titleSize={titleSize}
                  text={subtitle}
                />
              </div>
            </div>
          </div>
        )}
        <div className="row">
          {featuredSubsections.map((section, index) => (
            <div key={index} className="col-xs-12 col-lg-6">
              <BlockAnchor link={section.link} className={s.link}>
                {(attrs) => (
                  <OfferCard
                    section={section}
                    ctaLabel={attrs?.label}
                    featured
                  />
                )}
              </BlockAnchor>
            </div>
          ))}
        </div>
        <div className="row">
          {subsections.map((section, index) => (
            <div key={index} className="col-xs-12 col-sm-6 col-lg-3">
              <BlockAnchor link={section.link} className={s.link}>
                {(attrs) => (
                  <OfferCard section={section} ctaLabel={attrs?.label} />
                )}
              </BlockAnchor>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
